import React, { memo, useRef, useLayoutEffect } from "react";
import { FreeMode, Mousewheel } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import { gsap } from 'gsap';

import 'swiper/css';

import PageLayout from "../layouts/PageLayout";
import Work from "../components/Work";

import kusmiteaBG from "../images/work/kusmitea.jpg";
import kusmiteaLogo from "../images/icons/partners/kusmitea.svg";

import musemaillolBG from "../images/work/museemaillol.jpg";
import musemaillolLogo from "../images/icons/partners/museemaillol.svg";

import precisionBG from "../images/work/precisiontopo.jpg";
import precisionLogo from "../images/icons/partners/precisiontopo.svg";

import jourdefeteBG from "../images/work/jourdefete.jpg";
import jourdefeteLogo from "../images/icons/partners/jourdefete.svg";

import adopteuntoitBG from "../images/work/adopteuntoit.jpg";
import adopteuntoitLogo from "../images/icons/partners/adopteuntoit.svg";

import theroyalteaBG from "../images/work/theroyalteas.jpg";
import theroyalteaLogo from "../images/icons/partners/theroyalteas.svg";

import topexBG from "../images/work/topex.jpg";
import topexLogo from "../images/icons/partners/topex.svg";

const seoParams = {
    title: "Travaux",
    description: "Découvrez l'ensemble des projets sur lesquels j'ai eu la chance de travailler.",
};

const Travaux = memo(() => {
    const animationParent = useRef();

    useLayoutEffect(() => {
        const selector = gsap.utils.selector(animationParent);
        const animation = gsap.from(selector("div"), {
            duration: .8,
            ease: "power3.inOut",
            x: 60,
            opacity: 0,
            stagger: .1
        });

        return () => animation.kill();
    }, []);

    return (
        <PageLayout seo={seoParams} ref={animationParent}>
            <Swiper
                style={{ margin: "0 -50px" }}
                modules={[FreeMode, Mousewheel]}
                slidesPerView={'auto'}
                freeMode={true}
                mousewheel={true}
            >
                <SwiperSlide>
                    <Work
                        backgroundImage={kusmiteaBG}
                        description="Création d’un site e-commerce pour la marque Kusmi Tea à la Réunion."
                        href="https://kusmitea.re/"
                        logo={kusmiteaLogo}
                        overColor="#A31323" />
                </SwiperSlide>
                <SwiperSlide>
                    <Work
                        backgroundImage={musemaillolBG}
                        description="Développement du site vitrine du Musée Maillol."
                        href="https://www.museemaillol.com/"
                        logo={musemaillolLogo}
                        overColor="#A4BCFA" />
                </SwiperSlide>
                <SwiperSlide>
                    <Work
                        backgroundImage={precisionBG}
                        description="Création du site vitrine de la boutique Précision Topo."
                        href="https://precision-topo.com/"
                        logo={precisionLogo}
                        overColor="#ffff01" />
                </SwiperSlide>
                <SwiperSlide>
                    <Work
                        backgroundImage={jourdefeteBG}
                        description="Création du site vitrine de l'agence Jour de Fête."
                        href="https://www.jourdefete.re/"
                        logo={jourdefeteLogo}
                        overColor="#942984" />
                </SwiperSlide>
                <SwiperSlide>
                    <Work
                        backgroundImage={adopteuntoitBG}
                        description="Création du site vitrine de Adopte un Toit & création d'une application de recherche de biens immobiliers."
                        href="https://www.adopteuntoit.com/"
                        logo={adopteuntoitLogo}
                        overColor="#34D1C8" />
                </SwiperSlide>
                <SwiperSlide>
                    <Work
                        backgroundImage={theroyalteaBG}
                        description="Création du site e-commerce de la marque The Royal Teas."
                        href="https://theroyalteas.shop/"
                        logo={theroyalteaLogo}
                        overColor="#acd77a" />
                </SwiperSlide>
                <SwiperSlide>
                    <Work
                        backgroundImage={topexBG}
                        description="Création du site vitrine de la société de géomètre-expert Topex."
                        href="https://www.topex.fr/"
                        logo={topexLogo}
                        overColor="#001c37" />
                </SwiperSlide>
            </Swiper>
        </PageLayout>
    );
});

export default Travaux;