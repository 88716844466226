import React, { memo } from "react";
import styled from "styled-components";

import arrow from "../images/icons/arrow.svg";

const StyledWork = styled.div`
    position: relative;
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px;
    height: calc(75vh - 200px);

    background-image: url(${props => props.backgroundImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
    transition: width 0.4s ease-in-out, margin 0.4s ease-in-out;
    overflow: hidden;
    border-radius: 10px;
    margin: 0 100px;

    &:hover {
        width: 380px;
    }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        opacity: 0.7;
        background-color: ${props => props.overColor};
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        opacity: 0.4;
        background-color: ${props => props.theme.black};
    }

    a, p, h2 {
        position: relative;
        z-index: 1;
    }

    a {
        font-size: ${props => props.theme.texteMedium};
        font-weight: ${props => props.theme.boldWeight};
        display: flex;
        align-items: center;
        transition: transform 0.4s ease-in-out;

        &:hover {
            color: ${props => props.theme.white};
            transform: translateX(10px);
        }

        &:active {
            transform: scale(0.98);
        }
    }

    p {
        font-size: ${props => props.theme.texteMedium};
        font-weight: ${props => props.theme.mediumWeight};
        margin-bottom: 45px;
    }

    h2 {
        img {
            height: 80px;
            width: 200px;
            object-fit: contain;
            object-position: left;
        }
    }

    @media (max-width: 850px) {
        width: 300px;
        margin: 0 50px;

        &:hover {
            width: 330px;
        }   
    }
`

const Work = memo((props) => {
    return (
        <StyledWork backgroundImage={props.backgroundImage} overColor={props.overColor}>
            <h2><img src={props.logo} alt="Logo" /></h2>
            <p>{props.description}</p>
            <a href={props.href} target="_blank" rel="noopener noreferrer">Visiter le site <img style={{ marginLeft: 10 }} src={arrow} alt="Logo fléche" /></a>
        </StyledWork>
    );
});

export default Work;